<template lang="pug">
two-columns-layout
  //- Form heading and buttons
  heading Exam Information
    template(v-slot:action)
      div.flex-center.flex-nowrap
        //- Hide / unhide button
        v-btn.mr-5(icon @click.prevent="exam.hidden = !exam.hidden")
          v-icon(
            v-if="exam.hidden"
            size="32"
            color="#F9F9F9B3") $eye-close
          v-icon(v-else size="32") $eye-open
        //- Delete chapter button, only clickable in update page
        v-btn(
          icon
          :loading="isDeleting"
          :disabled="isDeleting || action === 'create'"
          @click.prevent="isDeleting = true")
          v-icon(size="32" color="#FF6E6E") $trashcan
  v-form(ref="form")
    div.d-flex
      //- Index, e.g. "Lec01"
      text-field.flex-grow-0.mr-6(
        v-model="exam.index"
        label="Index"
        required)
      //- Chapter title, e.g. "Introduction to C"
      text-field(
        v-model="exam.title"
        label="Title"
        required)
    div.flex-center
      //- Online meeting url
      text-field.mr-6(
        v-model="exam.meetingUrl"
        label="Online meeting url")
      v-switch.flex-grow-0(
        v-model="exam.requireVerification"
        label="Require verification")
    //- Announcement
    rich-text-editor(
      v-model="exam.announcement"
      label="Announcement")
    //- Problem picker
    problem-picker(
      v-model="problems"
      label="Problems")
    div.d-flex
      //- Start time of the chapter
      date-time-picker.flex-grow-1.mr-6(
        v-model="exam.startTime"
        label="Start Time"
        required
        :events="timeHighlight")
      //- Due time of the chapter
      date-time-picker.flex-grow-1(
        v-model="exam.dueTime"
        label="Due Time"
        required
        :events="timeHighlight")
  div.d-flex.mt-10
    //- Submit button
    primary-button.mr-9(
      :isWaiting="isLoading"
      @click="submitForm()") {{ action }}
    //- Cancel button
    primary-button(
      hollow
      :isWaiting="isLoading"
      @click="goToChapterList()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete {{ exam.index }}
    template(v-slot:content)
      p Do you really want to delete {{ exam.index }}?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteExam()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import DateTimePicker from '@/components/form/DateTimePicker.vue'
import ProblemPicker from '@/components/form/ProblemPicker.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    PrimaryButton,
    TextField,
    RichTextEditor,
    DateTimePicker,
    ProblemPicker,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:     'update',
      isLoading:  true,
      isDeleting: false,
      exam:       {
        // Index of this chapter ('midterm' for example)
        index:               this.$route.params.id || '',
        // Title of this chapter ('Midterm' for example)
        title:               '',
        // Is this chapter hidden from non-admin user
        hidden:              true,
        // The starting time of this chapter
        startTime:           null,
        // End time of this chapter
        dueTime:             null,
        // Announcement of this exam
        announcement:        '',
        // Online meeting room url for this exam
        meetingUrl:          '',
        // Whether this exam requires user to enter validation code
        requireVerification: true,
      },
      problems:  [],
      formDirty: false,
    }
  },
  computed: {
    ...mapState('chapter', ['examList']),
    ...mapGetters('problem', ['chapterProblems']),
  },
  watch: {
    exam: {
      handler () {
        if(!this.formDirty) {
          this.formDirty = true
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('chapter', ['createChapter', 'updateChapter', 'deleteChapter']),
    ...mapActions('problem', ['bindProblemsOnChapter']),
    // Set exam information
    setExam () {
      // Clear loading state
      this.isLoading = false

      // If this chapter has index, it means that this is an existing chapter
      // and user wnat to update it. Set form data to the chapter's data.
      if(this.exam.index) {
        this.exam = {
          ...this.exam,
          ...this.examList[this.exam.index],
        }

        this.problems = this.chapterProblems(this.exam.index).map(problem => problem.id)
      }
      // Else, it means that user is in create chapter page,
      // set action to 'create'
      else {
        this.action = 'create'
      }
    },
    // Return to chapter list page
    goToChapterList () {
      this.$router.push('/manage/chapter').catch(()=>{})
    },
    // Color highlight on above -time
    timeHighlight (value) {
      const date = new Date(value).toDateString()
      const colors = []

      // Start time is green
      if(this.exam.startTime && date === this.exam.startTime.toDateString()) {
        colors.push('#52CC75')
      }
      // Due time is red
      if(this.exam.dueTime && date === this.exam.dueTime.toDateString()) {
        colors.push('#FF6E6E')
      }

      return colors
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Decide which action to do
      this[`${this.action}Exam`]()
    },
    // Create exam
    createExam () {
      // Set loading state
      this.isLoading = true

      // Create exam
      this.createChapter({
        type:    'exams',
        payload: this.exam,
      })
        .then(() => this.bindProblemsOnChapter({
          ids:     this.problems,
          chapter: this.exam.index,
        }))
        // If success, show success message and return to chapter list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New exam created!',
          })
          this.goToChapterList()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update exam
    updateExam () {
      // Set loading state
      this.isLoading = true

      // Update exam
      this.updateChapter({
        type:    'exams',
        index:   this.$route.params.id,
        payload: this.exam,
      })
        .then(() => this.bindProblemsOnChapter({
          ids:     this.problems,
          chapter: this.exam.index,
        }))
        // If success, show success message
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Exam information updated!',
          })
          this.setExam()
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete exam
    deleteExam () {
      // Delete exam
      this.deleteChapter({
        type:  'exams',
        index: this.exam.index,
      })
        // If success, show success message and return to chapter list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Exam deleted!',
          })
          this.goToChapterList()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    this.setExam()
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
